import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import "../scss/modules/floating-btn-component.scss"
import ReactModal from 'react-modal'

class floatingBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }

  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <div className={"floating-btn-container"}>
        <button className={"floating-btn"} onClick={this.handleModalOpen}><FontAwesomeIcon icon={faPhone}/></button>
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          contentLabel="Modal Phone"
          className={"modal-phone"}
          overlayClassName={"modal-phone-overlay"}
        >
          <h2>Contactez-nous</h2>
          <a className="button-component" href="tel:0169399045">
            <p>Service <b>Radiologie-échographie-mammographie</b></p>
            <button><FontAwesomeIcon icon={faPhone}/> 01 69 39 90 45</button>
          </a>
          <a className="button-component" href="tel:0169391616">
            <p>Service <b>Scanner-IRM</b></p>
            <button><FontAwesomeIcon icon={faPhone}/> 01 69 39 16 16</button>
          </a>
          <button className={"close-modal-btn"} onClick={this.handleModalClose}>Fermer</button>
        </ReactModal>
      </div>
    )
  }
}

export default floatingBtn
